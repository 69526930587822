import React from 'react';
import tw from 'twin.macro';
import { FormattedMessage } from 'gatsby-plugin-intl';
import { socialMedia } from '@utils/variables';

import Text from '@components/text';
import Link from '@components/link';

const Wrapper = tw.div`sm:ml-95 md:ml-150 lg:ml-0 xl:-mr-40`;

const Subtitle = tw(Text)`mt-11 mb-8`;

const SocialMedia = () => (
  <Wrapper>
    <Text fontFamily="bebasNeue" fontSizes={22} fontWeight={600} letterSpacing={28} uppercase>
      <FormattedMessage id="social media.title" />
    </Text>
    <Subtitle
      fontSizes={14}
      fontWeight={600}
      lineHeight={1.93}
      letterSpacing={18}
      uppercase
    >
      <FormattedMessage id="social media.subtitle" />
    </Subtitle>
    {socialMedia.map(({ name, path }) => (
      <Link key={name} to={path} isExternal>
        <Text fontSizes={14} lineHeight={1.93} letterSpacing={18} underline uppercase>
          <FormattedMessage id={name} />
        </Text>
      </Link>
    ))}
  </Wrapper>
);

export default SocialMedia;
