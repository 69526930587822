import resolveConfig from 'tailwindcss/resolveConfig';
import tw, { css } from 'twin.macro';

import tailwindConfig from '@project/tailwind.config.js';

const fullConfig = resolveConfig(tailwindConfig);

const getFontFamily = (family) => ({
  openSans: tw`font-openSans`,
  bebasNeue: tw`font-bebasNeue`,
}[family]);

const getFontFamilyLg = (family) => ({
  openSans: tw`lg:font-openSans`,
  bebasNeue: tw`lg:font-bebasNeue`,
}[family]);

export const getResponsiveFontFamily = (value) => {
  if (typeof value === 'string') return getFontFamily(value);

  return css`${getFontFamily(value[0])} ${getFontFamilyLg(value[1])}`;
};

export const getFontSizes = (value) => {
  if (typeof value === 'number') return css`font-size: ${fullConfig.theme.fontSize[value]}`;

  return css`
    font-size: ${fullConfig.theme.fontSize[value[0]]};
  
    @media (min-width: ${fullConfig.theme.screens.lg}) {
      font-size: ${fullConfig.theme.fontSize[value[1]]};
    }
  `;
};

export const getFontWeight = (weight) => ({
  300: tw`font-300`,
  400: tw`font-400`,
  600: tw`font-600`,
  700: tw`font-700`,
}[weight]);

export const getFontWeightLg = (weight) => ({
  300: tw`lg:font-300`,
  400: tw`lg:font-400`,
  600: tw`lg:font-600`,
  700: tw`lg:font-700`,
}[weight]);

export const getResponsiveFontWeight = (value) => {
  if (typeof value === 'number') return getFontWeight(value);

  return css`${getFontWeight(value[0])} ${getFontWeightLg(value[1])}`;
};

export const getColor = (value) => {
  if (typeof value === 'string') return css`color: ${fullConfig.theme.colors[value]};`;

  return css`
    color: ${fullConfig.theme.colors[value[0]]};

    @media (min-width: ${fullConfig.theme.screens.lg}) {
      color: ${fullConfig.theme.colors[value[1]]};
    }
  `;
};

export const getLetterSpacing = (value) => {
  if (typeof value === 'number') return css`letter-spacing: ${fullConfig.theme.letterSpacing[value]}`;

  return css`
    letter-spacing: ${fullConfig.theme.letterSpacing[value[0]]};
  
    @media (min-width: ${fullConfig.theme.screens.lg}) {
      letter-spacing: ${fullConfig.theme.letterSpacing[value[1]]};
    }
  `;
};

export const getLineHeight = (value) => {
  if (typeof value === 'number') return css`line-height: ${value}`;

  return css`
    line-height: ${value[0]};
  
    @media (min-width: ${fullConfig.theme.screens.lg}) {
      line-height: ${value[1]};
    }
  `;
};

export const customContainer = css`
  ${tw`container lg:max-w-1000 xl:max-w-1105`}
`;
