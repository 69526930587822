import React from 'react';
import { FormattedMessage } from 'gatsby-plugin-intl';
import tw, { styled } from 'twin.macro';

import { customContainer } from '@utils/twin-macro';
import Text from '@components/text';

const Container = styled(Text)`
  ${customContainer}
  ${tw`mb-18 px-11 md:mb-36 lg:px-0`}
`;

const Copyrights = () => (
  <Container fontSizes={14} color="green">
    <FormattedMessage id="copyrights" />
  </Container>
);

export default Copyrights;
