module.exports = {
  purge: [],
  darkMode: false,
  theme: {
    container: {
      center: true,
    },
    screens: {
      sm: '640px',
      md: '768px',
      lg: '1024px',
      xl: '1280px',
    },
    fontFamily: {
      openSans: ['"open-sans"', 'sans-serif'],
      bebasNeue: ['"bebas-neue-by-fontfabric"', 'sans-serif'],
    },
    fontWeight: {
      300: 300,
      400: 400,
      600: 600,
      700: 700,
    },
    fontSize: {
      9: '9px',
      10: '10px',
      12: '12px',
      13: '13px',
      14: '14px',
      16: '16px',
      18: '18px',
      20: '20px',
      22: '22px',
      23: '23px',
      24: '24px',
      25: '25px',
      28: '28px',
      29: '29.5px',
      30: '30px',
      32: '32px',
      36: '36px',
      40: '40px',
      41: '41px',
      42: '42px',
      43: '43px',
      45: '45px',
      48: '48px',
      54: '54px',
      56: '56px',
      58: '58px',
      64: '64px',
      72: '72px',
    },
    lineHeight: {
      snug: '1.36',
      relaxed: '1.71',
    },
    colors: {
      primary: '#231F20',
      blue: '#91BCD4',
      backgroundBlue: '#D7EFFD',
      blueDark: '#646C7A',
      404: '#32284C',
      greenBlog: '#E2F0EE',
      green: '#93BEB6',
      greenDark: '#80A39C',
      backgroundGreen: '#D3E7E2',
      backgroundGreenLight: '#DDECE9',
      red: '#F5CFC7',
      redBlog: '#AC6F61',
      redDark: '#A57168',
      brown: '#482525',
      brownDot: '#6D0E00',
      black: '#000',
      pink: '#F2C1B6',
      'pink-300': 'rgba(242, 193, 182, .3)',
      beige: '#FAF3EB',
      grey: '#D8D8D8',
      greyFont: '#767D8A',
      greyBlog: '#979797',
      white: '#FFF',
      transparent: 'rgba(255, 255, 255, 0)',
      error: '#E74C3C',
    },
    fill: (theme) => ({
      redDark: theme('colors.redDark'),
    }),
    stroke: (theme) => ({
      error: theme('colors.error'),
    }),
    letterSpacing: {
      '-53': '-0.53px',
      '-45': '-0.45px',
      0: 0,
      1: '1px',
      10: '.01px',
      8: '.08px',
      9: '.09px',
      11: '0.11px',
      13: '0.13px',
      15: '0.15px',
      16: '0.16px',
      18: '0.18px',
      20: '0.2px',
      22: '0.22px',
      23: '0.23px',
      28: '0.28px',
      30: '0.30px',
      31: '0.31px',
      34: '0.34px',
      35: '0.35px',
      38: '0.38px',
      40: '0.40px',
      45: '0.45px',
      52: '0.52px',
      56: '0.56px',
      60: '0.60px',
      67: '0.67px',
      68: '0.68px',
      70: '0.70px',
      72: '0.72px',
      107: '1.07px',
      114: '1.14px',
      130: '1.3px',
      138: '1.38px',
      146: '1.46px',
    },
    spacing: {
      '-40': '-40px',
      '-12': '-12px',
      0: '0',
      1: '1px',
      2: '2px',
      3: '3px',
      4: '4px',
      5: '5px',
      6: '6px',
      7: '7px',
      8: '8px',
      9: '9px',
      10: '10px',
      11: '11px',
      12: '12px',
      13: '13px',
      14: '14px',
      15: '15px',
      16: '16px',
      17: '17px',
      18: '18px',
      20: '20px',
      21: '21px',
      22: '22px',
      23: '23px',
      24: '24px',
      25: '25px',
      26: '26px',
      27: '27px',
      28: '28px',
      30: '30px',
      32: '32px',
      34: '34px',
      35: '35px',
      36: '36px',
      38: '38px',
      40: '40px',
      42: '42px',
      44: '44px',
      45: '45px',
      46: '46px',
      48: '48px',
      50: '50px',
      52: '52px',
      54: '54px',
      55: '55px',
      56: '56px',
      58: '58px',
      60: '60px',
      62: '62px',
      64: '64px',
      65: '65px',
      66: '66px',
      68: '68px',
      70: '70px',
      71: '71px',
      72: '72px',
      74: '74px',
      75: '75px',
      77: '77px',
      80: '80px',
      82: '82px',
      84: '84px',
      85: '85px',
      86: '86px',
      90: '90px',
      94: '94px',
      95: '95px',
      96: '96px',
      98: '98px',
      100: '100px',
      102: '102px',
      106: '106px',
      110: '110px',
      115: '115px',
      120: '120px',
      122: '122px',
      126: '126px',
      130: '130px',
      135: '135px',
      140: '140px',
      148: '148px',
      150: '150px',
      152: '152px',
      155: '155px',
      160: '160px',
      163: '163px',
      165: '165px',
      170: '170px',
      172: '172px',
      175: '175px',
      180: '180px',
      182: '182px',
      188: '188px',
      190: '190px',
      200: '200px',
      207: '207px',
      210: '210px',
      212: '212px',
      220: '220px',
      224: '224px',
      230: '230px',
      240: '240px',
      250: '250px',
      265: '265px',
      270: '270px',
      275: '275px',
      280: '280px',
      288: '288px',
      290: '290px',
      300: '300px',
      305: '305px',
      306: '306px',
      310: '310px',
      314: '314px',
      320: '320px',
      330: '330px',
      340: '340px',
      343: '343px',
      345: '345px',
      350: '350px',
      360: '360px',
      370: '370px',
      375: '375px',
      380: '380px',
      390: '390px',
      400: '400px',
      405: '405px',
      418: '418px',
      420: '420px',
      422: '422px',
      428: '428px',
      430: '430px',
      433: '433px',
      440: '440px',
      450: '450px',
      460: '460px',
      470: '470px',
      480: '480px',
      490: '490px',
      495: '495px',
      500: '500px',
      515: '515px',
      520: '520px',
      530: '530px',
      535: '535px',
      540: '540px',
      545: '545px',
      550: '550px',
      575: '575px',
      580: '580px',
      590: '590px',
      595: '595px',
      600: '600px',
      610: '610px',
      620: '620px',
      630: '630px',
      640: '640px',
      650: '650px',
      660: '660px',
      680: '680px',
      690: '690px',
      700: '700px',
      730: '730px',
      740: '740px',
      750: '750px',
      780: '780px',
      800: '800px',
      890: '890px',
      900: '900px',
      980: '980px',
      1024: '1024px',
      1100: '1100px',
      1177: '1177px',
      1280: '1280px',
      1400: '1400px',
    },
    extend: {
      maxWidth: {
        480: '480px',
        1000: '1000px',
        1005: '1005px',
        1105: '1105px',
        1440: '1440px',
      },
      transitionDuration: {
        0: '0ms',
        250: '250ms',
        400: '400ms',
      },
      opacity: {
        45: '.45',
        63: '.63',
      },
      zIndex: {
        '-1': '-1',
        1: '1',
      },
      boxShadow: {
        custom: '0 3px 10px 0px rgb(0 0 0 / 50%)',
        coffeeHouse: '0 2px 4px 0 rgba(72, 37, 37, 0.35)',
      },
      display: ['hover', 'focus', 'first', 'last'],
    },
  },
  corePlugins: {
    container: false,
  },
};
