import React from 'react';
import tw, { styled } from 'twin.macro';
import { FormattedMessage } from 'gatsby-plugin-intl';

import Text from '@components/text';

const Info = styled(Text)`
  ${tw`hidden absolute -bottom-25 lg:-bottom-20 xl:-bottom-30`}
  ${({ error }) => (error ? tw`text-error` : tw`text-greenDark`)}

  &.show { ${tw`block`} }
`;

const Message = () => (
  <>
    <Info id="newsletter-success" fontSizes={12} lineHeight={1.5}>
      <FormattedMessage id="newsletter.success" />
    </Info>
    <Info id="newsletter-error" fontSizes={12} lineHeight={1.5} error>
      <FormattedMessage id="newsletter.error" />
    </Info>
    <Info id="newsletter-format" fontSizes={12} lineHeight={1.5} error>
      <FormattedMessage id="newsletter.format" />
    </Info>
  </>
);

export default Message;
