import React from 'react';
import { FormattedMessage } from 'gatsby-plugin-intl';
import tw, { styled } from 'twin.macro';
import {
  navigationFooterColLeft,
  navigationFooterColRight,
  navigationContact,
} from '@utils/navigation';

import Text from '@components/text';
import NavLink from '@components/footer/nav-link';
import Link from '@components/link';

const Links = styled.div`
  ${tw`mb-25`}
  
  :nth-of-type(1) {
    ${tw`lg:ml-50 xl:ml-110`}
  }

  :nth-of-type(2) {
    ${tw`sm:ml-40 lg:mx-50 xl:mx-55`}
  }
`;

const Nav = () => (
  <>
    <Links>
      {navigationFooterColLeft.map((item) => (
        <NavLink key={item.name} item={item} />
      ))}
    </Links>
    <Links>
      {navigationFooterColRight.map((item) => (
        <NavLink key={item.name} item={item} />
      ))}
      <Link to={navigationContact.path} isExternal={navigationContact.externalLink}>
        <Text fontSizes={14} fontWeight={700} lineHeight={1.93} letterSpacing={18} uppercase>
          <FormattedMessage id={navigationContact.translation} />
        </Text>
      </Link>
    </Links>
  </>
);

export default Nav;
