import React from 'react';
import tw from 'twin.macro';
import PropTypes from 'prop-types';

import EtnoLogo from '@images/logo';
import Link from '@components/link';

const Logo = tw(EtnoLogo)`relative w-70 lg:w-94 z-40`;

const CompanyLogo = ({ changeMenuState }) => (
  <Link to="/" onClick={() => changeMenuState(false)}>
    <Logo />
  </Link>
);

CompanyLogo.propTypes = {
  changeMenuState: PropTypes.func.isRequired,
};

export default CompanyLogo;
