import React from 'react';
import PropTypes from 'prop-types';
import tw, { styled } from 'twin.macro';

import {
  getResponsiveFontFamily,
  getResponsiveFontWeight,
  getFontSizes,
  getColor,
  getLineHeight,
  getLetterSpacing,
} from '@utils/twin-macro';

const fontFamilys = ['openSans', 'bebasNeue'];
const fontWeights = [300, 400, 600, 700];
const colors = ['primary', 'white', 'black', 'green', 'greenDark', 'pink', 'blue', 'redDark', 'blueDark', 'redBlog', 'greyFont'];

const StyledText = styled.div`
  ${tw`whitespace-pre-line`}
  ${(props) => getResponsiveFontFamily(props.fontFamily)};
  ${(props) => getResponsiveFontWeight(props.fontWeight)};
  ${(props) => getFontSizes(props.fontSizes)};
  ${(props) => getLineHeight(props.lineHeight)};
  ${(props) => (props.underline ? tw`underline` : tw`no-underline`)};
  ${(props) => props.uppercase && tw`uppercase`};
  ${(props) => props.italic && tw`italic`};
  ${(props) => getColor(props.color)};
  ${(props) => getLetterSpacing(props.letterSpacing)};
  
  b {
    font-weight: 700;
  }
`;

const Text = ({
  children, fontFamily, fontWeight, fontSizes, underline, uppercase, italic, color, lineHeight,
  letterSpacing, ...props
}) => (
  <StyledText
    fontFamily={fontFamily}
    fontWeight={fontWeight}
    fontSizes={fontSizes}
    underline={underline}
    uppercase={uppercase}
    italic={italic}
    color={color}
    lineHeight={lineHeight}
    letterSpacing={letterSpacing}
    {...props}
  >
    {children}
  </StyledText>
);

Text.defaultProps = {
  fontFamily: 'openSans',
  fontWeight: 400,
  fontSizes: [14, 22],
  underline: false,
  uppercase: false,
  italic: false,
  color: 'primary',
  lineHeight: 1,
  letterSpacing: 0,
};

Text.propTypes = {
  children: PropTypes.node.isRequired,
  fontFamily: PropTypes.oneOfType([
    PropTypes.oneOf(fontFamilys),
    PropTypes.arrayOf(PropTypes.oneOf(fontFamilys)),
  ]),
  fontWeight: PropTypes.oneOfType([
    PropTypes.oneOf(fontWeights),
    PropTypes.arrayOf(PropTypes.oneOf(fontWeights)),
  ]),
  fontSizes: PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf(PropTypes.number)]),
  underline: PropTypes.bool,
  uppercase: PropTypes.bool,
  italic: PropTypes.bool,
  color: PropTypes.oneOfType([
    PropTypes.oneOf(colors),
    PropTypes.arrayOf(PropTypes.oneOf(colors)),
  ]),
  lineHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf(PropTypes.number)]),
  letterSpacing: PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf(PropTypes.number)]),
};

export default Text;
