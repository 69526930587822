import React from 'react';
import PropTypes from 'prop-types';
import tw, { styled } from 'twin.macro';

const Wrapper = tw.div`relative flex justify-center w-30 h-30 cursor-pointer z-50 lg:hidden`;

const StyledHamburgerBar = tw.span`
  absolute block w-23 h-2 bg-brown opacity-100 rounded-full transform
  transition-all ease-in-out duration-300
`;

const OutsideBar = styled(StyledHamburgerBar)`
  top: ${({ top }) => (top ? 8 : 20)}px;
  
  ${Wrapper}.active & {
    ${tw`opacity-0`}
  }
`;

const MiddleBar = styled(StyledHamburgerBar)`
  top: 14px;
  
  ${Wrapper}.active & {
    ${tw`transform -rotate-45`}
    
    :nth-of-type(2) {
      ${tw`rotate-45`}
    }
  }
`;

const Hamburger = ({ isMenuActive, changeMenuState }) => (
  <Wrapper
    onClick={() => changeMenuState(isMenuActive)}
    className={isMenuActive ? 'active' : ''}
  >
    <OutsideBar top />
    <MiddleBar />
    <MiddleBar />
    <OutsideBar />
  </Wrapper>
);

Hamburger.propTypes = {
  isMenuActive: PropTypes.bool.isRequired,
  changeMenuState: PropTypes.func.isRequired,
};

export default Hamburger;
