export const breakpoints = {
  xs: 0,
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
};

export const socialMedia = [
  {
    name: 'social media.fb',
    path: 'https://www.facebook.com/EtnoCafeKawiarnie/',
  },
  {
    name: 'social media.instagram',
    path: 'https://www.instagram.com/etnocafe',
  },
  {
    name: 'social media.linkedin',
    path: 'https://www.linkedin.com/company/etno-cafe/',
  },
  {
    name: 'social media.youtube',
    path: 'https://www.youtube.com/channel/UCtHvJY3ZQQCzUa8cwcBuDyg',
  },
];

export const coffeeLinks = [
  'https://sklep.etnocafe.pl/304-kawy-jasno-palone',
  'https://sklep.etnocafe.pl/305-kawy-ciemniej-palone',
  'https://sklep.etnocafe.pl/306-kawy-single-origin',
  'https://sklep.etnocafe.pl/307-kawy-do-ekspresu-cisnieniowego',
  'https://sklep.etnocafe.pl/308-kawy-slodkie',
  'https://sklep.etnocafe.pl/309-kawy-cytrusowe',
  'https://sklep.etnocafe.pl/310-kawy-orzechowe',
];

export const testimonials = [
  {
    name: 'weWork',
    rounded: false,
  },
  {
    name: 'primulator',
    rounded: false,
  },
  {
    name: 'coffeeDesk',
    rounded: true,
  },
  {
    name: 'konesso',
    rounded: false,
  },
  {
    name: 'unroasted',
    rounded: false,
  },
];
