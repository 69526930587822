import React from 'react';
import tw from 'twin.macro';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import { IntlContextConsumer } from 'gatsby-plugin-intl';

import Text from '@components/text';

const getLocalizedPath = require('@i18n/utils/getLocalizedPath');
const languagesConfig = require('@i18n/config/languages');

const languageNames = { pl: 'pl |', en: 'eng' };

const Wrapper = tw.div`flex justify-between w-68 mt-25 mx-auto lg:w-52 lg:mt-0 lg:ml-0 lg:mr-4`;

const Lang = tw(Link)`cursor-pointer`;

const TextNoWrap = tw(Text)`whitespace-nowrap`;

const NavLang = ({ switchPath }) => {
  const pathName = typeof window !== 'undefined' ? window.location.pathname : '/';

  const handlePath = (language, currentLocale) => {
    if (switchPath.length) return switchPath[language === 'pl' ? 0 : 1];

    const { routes } = languagesConfig.find((lang) => lang.locale === currentLocale);
    const path = currentLocale === 'pl' ? pathName : pathName.substring(3);
    const pathParts = path.split('/').filter((pathPart) => !!pathPart.length);
    const langPath = pathParts.join('/');
    const originPath = Object.keys(routes).find((key) => routes[key] === `/${langPath}`) || '/';

    return getLocalizedPath(originPath, language);
  };

  return (
    <Wrapper>
      <IntlContextConsumer>
        {({ languages, language: currentLocale }) => languages.map((language) => (
          <Lang
            key={language}
            to={handlePath(language, currentLocale)}
          >
            <TextNoWrap
              fontSizes={[16, 14]}
              fontWeight={[400, 300]}
              lineHeight={1.4}
              letterSpacing={18}
              uppercase
            >
              {languageNames[language]}
            </TextNoWrap>
          </Lang>
        ))}
      </IntlContextConsumer>
    </Wrapper>
  );
};

NavLang.defaultProps = {
  switchPath: [],
};

NavLang.propTypes = {
  switchPath: PropTypes.arrayOf(PropTypes.string),
};

export default NavLang;
