import React from 'react';
import tw from 'twin.macro';
import { FormattedMessage } from 'gatsby-plugin-intl';

import Text from '@components/text';

const Wrapper = tw.div`mb-26`;

const Subscribe = tw(Text)`mt-26 mb-8`;

const NewsletterDesc = () => (
  <Wrapper>
    <Text fontFamily="bebasNeue" fontSizes={22} fontWeight={600} lineHeight={1.1} letterSpacing={28}>
      <FormattedMessage id="newsletter.header" />
    </Text>
    <Subscribe fontSizes={14} fontWeight={600} lineHeight={1.4} letterSpacing={18}>
      <FormattedMessage id="newsletter.subscribe" />
    </Subscribe>
    <Text fontSizes={14} fontWeight={[400, 300]} lineHeight={1.4} letterSpacing={18}>
      <FormattedMessage id="newsletter.description" />
    </Text>
  </Wrapper>
);

export default NewsletterDesc;
