import React from 'react';
import tw from 'twin.macro';
import { FormattedMessage } from 'gatsby-plugin-intl';

import Text from '@components/text';
import ButtonDefault from '@components/button';
import LinkDefault from '@components/link';
import { navigationShop } from '@utils/navigation';

const Wrapper = tw.div`flex lg:ml-28 lg:mr-30`;

const Link = tw(LinkDefault)`mx-auto`;

const Button = tw(ButtonDefault)`mt-32`;

const NavBtn = () => (
  <Wrapper>
    <Link to={navigationShop.path} isExternal={navigationShop.externalLink}>
      <Button>
        <Text color="white" fontSizes={16} fontWeight={600} lineHeight={1.4} letterSpacing={20}>
          <FormattedMessage id={navigationShop.translation} />
        </Text>
      </Button>
    </Link>
  </Wrapper>
);

export default NavBtn;
