import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import tw, { styled } from 'twin.macro';
import { customContainer } from '@utils/twin-macro';

import Hamburger from '@components/header/hamburger';
import Nav from '@components/header/nav';
import CompanyLogo from '@components/header/logo';

const StyledHeader = styled.header`
  ${customContainer}
  ${tw`flex justify-between items-center pt-12 px-10 pb-16 lg:py-20 lg:px-0`}
`;

const Header = ({ switchPath }) => {
  const [isMenuActive, toggleMenu] = useState(false);

  const handleMenu = () => {
    toggleMenu(!isMenuActive);
    document.body.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  return (
    <StyledHeader>
      <Helmet bodyAttributes={{ class: `${isMenuActive ? 'menu-block' : ''}` }} />
      <CompanyLogo changeMenuState={toggleMenu} />
      <Hamburger isMenuActive={isMenuActive} changeMenuState={handleMenu} />
      <Nav
        isMenuActive={isMenuActive}
        changeMenuState={handleMenu}
        switchPath={switchPath}
      />
    </StyledHeader>
  );
};

Header.defaultProps = {
  switchPath: [],
};

Header.propTypes = {
  switchPath: PropTypes.arrayOf(PropTypes.string),
};

export default Header;
