module.exports = [
  {
    default: true,
    locale: 'pl',
    label: 'Polish',
    routes: {
      '/': '/',
      '/about-etno-cafe': '/o-etno-cafe',
      '/coffee': '/kawa',
      '/coffee-houses': '/kawiarnie',
      '/for-business': '/dla-biznesu',
      '/404': '/404',
      '/404.html': '/404.html',
      '/privacy-policy': '/polityka-prywatnosci',
      '/rules': '/regulaminy',
      '/contact': '/kontakt',
      '/blog': '/blog',
      '/job': '/strona/praca',
    },
  },
  {
    locale: 'en',
    label: 'English',
    routes: {
      '/': '/',
      '/about-etno-cafe': '/about-etno-cafe',
      '/coffee': '/coffee',
      '/coffee-houses': '/coffee-shops',
      '/for-business': '/for-business',
      '/404': '/404',
      '/404.html': '/404.html',
      '/privacy-policy': '/privacy-policy',
      '/rules': '/rules',
      '/contact': '/contact',
      '/blog': '/blog',
      '/job': '/page/jobs',
    },
  },
];
