const fetch = require('node-fetch');

const url = '/.netlify/functions/newsletter';

let msg = {};
let wasFormSent = false;

const validateEmail = (value) => /\S+@\S+\.\S+/.test(String(value).toLowerCase());

const show = (item) => item.classList.add('show');
const hide = (item) => item.classList.remove('show');

const getItems = () => {
  msg = {
    success: document.getElementById('newsletter-success'),
    error: document.getElementById('newsletter-error'),
    format: document.getElementById('newsletter-format'),
  };
};

export const addContact = () => {
  const input = document.getElementById('newsletter');
  const email = input.value;
  wasFormSent = true;
  getItems();

  if (validateEmail(email)) {
    hide(msg.format);

    fetch(`${url}?mail=${email}`, { method: 'GET' })
      .then((response) => response.text())
      .then(() => {
        show(msg.success);
        wasFormSent = false;
        input.value = '';
        input.addEventListener('change', () => hide(msg.success));
      })
      .catch(() => {
        show(msg.error);
        input.addEventListener('change', () => hide(msg.error));
      });
  } else if (wasFormSent) {
    show(msg.format);
  }
};

export const handleChange = (e) => {
  const isEmailProper = validateEmail(e.target.value);

  getItems();
  hide(msg.success);
  hide(msg.error);

  if (e.keyCode === 13) {
    addContact();
  } else if (isEmailProper) {
    hide(msg.format);
  } else if (wasFormSent) {
    show(msg.format);
  }
};
