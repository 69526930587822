import React from 'react';
import tw from 'twin.macro';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'gatsby-plugin-intl';

import Text from '@components/text';
import Link from '@components/link';

const StyledText = tw(Text)`whitespace-nowrap lg:-mr-18`;

const NavLink = ({ item }) => (
  <Link to={item.path} isExternal={item.externalLink}>
    <StyledText
      fontSizes={14}
      lineHeight={1.93}
      fontWeight={[400, 300]}
      letterSpacing={18}
      uppercase
    >
      <FormattedMessage id={item.translation} />
    </StyledText>
  </Link>
);

NavLink.propTypes = {
  item: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool,
      PropTypes.array,
    ]),
  ).isRequired,
};

export default NavLink;
