import React from 'react';
import PropTypes from 'prop-types';
import tw from 'twin.macro';

const Btn = tw.button`
  mt-16 pt-11 pr-42 pb-10 pl-44 bg-primary rounded-3xl transition duration-400
  focus:outline-none hover:shadow-none
  lg:m-0 lg:shadow-custom
`;

const Button = ({ children, ...props }) => (
  <Btn {...props}>
    {children}
  </Btn>
);

Button.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Button;
