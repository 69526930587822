const navigation = [
  {
    name: 'Etno',
    translation: 'navigation.about',
    path: '/about-etno-cafe',
    places: ['footerColLeft'],
    externalLink: false,
  },
  {
    name: 'Etno cafe',
    translation: 'navigation.etno cafe',
    path: '/about-etno-cafe',
    places: ['header'],
    externalLink: false,
  },
  {
    name: 'Coffee',
    translation: 'navigation.coffee',
    path: '/coffee',
    places: ['header', 'footerColLeft'],
    externalLink: false,
  },
  {
    name: 'Read about coffee',
    translation: 'navigation.read about coffee',
    path: '/coffee',
    places: ['404'],
    externalLink: false,
  },
  {
    name: 'Coffee houses',
    translation: 'navigation.coffee houses',
    path: '/coffee-houses',
    places: ['header', 'footerColLeft', '404'],
    externalLink: false,
  },
  {
    name: 'Business',
    translation: 'navigation.business',
    path: '/for-business',
    places: ['header', 'footerColLeft'],
    externalLink: false,
  },
  {
    name: 'Blog',
    translation: 'navigation.blog',
    path: '/blog',
    places: ['header', 'footerColLeft', '404'],
    externalLink: false,
  },
  {
    name: 'About us',
    translation: 'navigation.about',
    path: '/about-etno-cafe',
    places: ['404'],
    externalLink: false,
  },
  {
    name: 'Shop',
    translation: 'navigation.shop',
    path: 'https://sklep.etnocafe.pl/',
    places: ['footerColLeft', '404'],
    externalLink: true,
  },
  {
    name: 'Career',
    translation: 'navigation.career',
    path: 'https://pracodawcy.pracuj.pl/etno-cafe-sp-z-o-o,166711',
    places: ['footerColRight'],
    externalLink: true,
  },
  {
    name: 'Privacy policy',
    translation: 'navigation.privacy policy',
    path: '/privacy-policy',
    places: ['footerColRight'],
    externalLink: false,
  },
  {
    name: 'Rules',
    translation: 'navigation.rules',
    path: '/rules',
    places: ['footerColRight'],
    externalLink: false,
  },
  {
    name: 'Contact',
    translation: 'navigation.contact',
    path: '/contact',
    places: ['404'],
    externalLink: false,
  },
  {
    name: 'Favorite Coffee',
    translation: 'navigation.favorite coffee',
    path: 'https://sklep.etnocafe.pl/',
    places: [],
    externalLink: true,
  },
  {
    name: 'Contact us',
    translation: 'navigation.contact with us',
    path: '/for-business',
    places: [],
    externalLink: false,
  },
  {
    name: 'Contact us short',
    translation: 'navigation.contact with us short',
    path: '/contact',
    places: [],
    externalLink: false,
  },
  {
    name: 'We brew',
    translation: 'navigation.splash',
    path: 'https://sklep.etnocafe.pl/290-cold-brew-coffee',
    places: [],
    externalLink: true,
  },
  {
    name: 'We roast',
    translation: 'navigation.see how',
    path: '/coffee',
    hash: 'howToBrew',
    places: [],
    externalLink: false,
  },
  {
    name: 'We serve',
    translation: 'navigation.discover the cafe',
    path: '/coffee-houses',
    places: [],
    externalLink: false,
  },
  {
    name: 'Go to shop',
    translation: 'navigation.go to shop',
    path: 'https://sklep.etnocafe.pl/',
    places: [],
    externalLink: true,
  },
  {
    name: 'Find in shop',
    translation: 'navigation.find in shop',
    path: 'https://sklep.etnocafe.pl/240-kawa-etno',
    places: [],
    externalLink: true,
  },
  {
    name: 'Cold Brew',
    translation: 'navigation.check how it is created',
    path: '/',
    places: [],
    externalLink: false,
  },
  {
    name: 'Home',
    translation: 'navigation.go back to homepage',
    path: '/',
    places: [],
    externalLink: false,
  },
  {
    name: 'See coffee',
    translation: 'navigation.see coffee',
    path: '/coffee',
    places: [],
    externalLink: false,
  },
];

const findNavItemsByPlace = (place) => navigation
  .filter(({ places }) => places.find((str) => str === place));

const findNavItemByName = (item) => navigation.find(({ name }) => name === item);

export const navigationHeader = findNavItemsByPlace('header');

export const navigationFooterColLeft = findNavItemsByPlace('footerColLeft');

export const navigationFooterColRight = findNavItemsByPlace('footerColRight');

export const navigation404 = findNavItemsByPlace('404');

export const navigationShop = findNavItemByName('Shop');

export const navigationContact = findNavItemByName('Contact');

export const navigationAbout = findNavItemByName('Etno cafe');

export const navigationCoffee = findNavItemByName('Favorite Coffee');

export const navigationContactUs = findNavItemByName('Contact us');

export const navigationContactUsShort = findNavItemByName('Contact us short');

export const navigationWeBrew = findNavItemByName('We brew');

export const navigationWeRoast = findNavItemByName('We roast');

export const navigationWeServe = findNavItemByName('We serve');

export const navigationRules = findNavItemByName('Rules');

export const navigationGoToShop = findNavItemByName('Go to shop');

export const navigationFindInShop = findNavItemByName('Find in shop');

export const navigationColdBrew = findNavItemByName('Cold Brew');

export const navigationHome = findNavItemByName('Home');

export const navigationSeeCoffee = findNavItemByName('See coffee');
