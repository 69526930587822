import React from 'react';
import tw from 'twin.macro';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'gatsby-plugin-intl';

import Text from '@components/text';
import Link from '@components/link';
import { navigationHeader } from '@utils/navigation';

const StyledText = tw(Text)`
  py-16 text-center border-pink-300 border-b
  lg:mx-16 lg:p-0 lg:border-0
`;

const NavItems = ({ changeMenuState }) => (
  <>
    {navigationHeader.map(({
      name, path, externalLink, translation,
    }) => (
      <Link
        key={name}
        to={path}
        isExternal={externalLink}
        onClick={() => changeMenuState(false)}
      >
        <StyledText
          fontFamily={['bebasNeue', 'openSans']}
          fontSizes={[28, 16]}
          fontWeight={[400, 300]}
          lineHeight={[1.24, 1.4]}
          letterSpacing={[35, 20]}
        >
          <FormattedMessage id={translation} />
        </StyledText>
      </Link>
    ))}
  </>
);

NavItems.propTypes = {
  changeMenuState: PropTypes.func.isRequired,
};

export default NavItems;
