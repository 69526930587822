import React from 'react';
import { FormattedMessage, useIntl } from 'gatsby-plugin-intl';
import tw, { styled } from 'twin.macro';
import { handleChange, addContact } from '@utils/newsletter.js';

import Text from '@components/text';
import Message from '@components/footer/newsletter-msg';

const Wrapper = tw.div`relative mb-45 sm:ml-95 md:ml-150 lg:ml-50 xl:ml-60`;

const News = tw(Text)`mt-10 mb-30`;

const Input = styled.input`
  width: 285px;
  height: 38px;
  border-radius: 19px;
  border: solid .6px rgba(72, 37, 37, .6);
  ${tw`font-openSans mb-15 py-10 px-21 text-14 text-brown tracking-18 focus:outline-none`}

  ::placeholder {
    ${tw`font-openSans text-14 text-brown opacity-60 tracking-18`}
  }
`;

const Submit = tw(Text)`cursor-pointer`;

const Newsletter = () => {
  const intl = useIntl();

  return (
    <Wrapper>
      <Text fontSizes={14} fontWeight={600} lineHeight={1.4} letterSpacing={18} uppercase>
        <FormattedMessage id="newsletter.info" />
      </Text>
      <News fontSizes={14} fontWeight={[400, 300]} lineHeight={1.4} letterSpacing={18}>
        <FormattedMessage id="newsletter.news" />
      </News>
      <Input
        id="newsletter"
        type="email"
        placeholder={intl.formatMessage({ id: 'newsletter.placeholder' })}
        onKeyDown={handleChange}
      />
      <Submit
        onClick={addContact}
        fontSizes={14}
        fontWeight={600}
        lineHeight={1.4}
        letterSpacing={18}
        underline
        uppercase
      >
        <FormattedMessage id="newsletter.submit" />
      </Submit>
      <Message />
    </Wrapper>
  );
};

export default Newsletter;
