import React from 'react';
import tw, { styled } from 'twin.macro';

import { customContainer } from '@utils/twin-macro';
import SocialMedia from '@components/footer/social-media';
import Nav from '@components/footer/nav';
import Newsletter from '@components/footer/newsletter';
import NewsletterDesc from '@components/footer/newsletter-desc';

const Wrapper = styled.footer`
  ${customContainer}
  ${tw`pt-40 px-11 pb-26 lg:flex lg:px-0`}
`;

const Flex = tw.div`sm:flex`;

const Footer = () => (
  <Wrapper>
    <Flex>
      <NewsletterDesc />
      <Newsletter />
    </Flex>
    <Flex>
      <Nav />
      <SocialMedia />
    </Flex>
  </Wrapper>
);

export default Footer;
