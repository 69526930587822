import React from 'react';
import { useIntl } from 'gatsby-plugin-intl';
import { Link as GatsbyLink } from 'gatsby';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import PropTypes from 'prop-types';

const getLocalizedPath = require('@i18n/utils/getLocalizedPath');

const Link = ({
  children, to, hash, isExternal, ...props
}) => {
  const intl = useIntl();

  if (isExternal) {
    return (
      <a href={to} target="_blank" rel="noreferrer" {...props}>
        {children}
      </a>
    );
  }

  return hash ? (
    <AnchorLink to={`${getLocalizedPath(to, intl.locale)}#${hash}`} stripHash {...props}>
      {children}
    </AnchorLink>
  ) : (
    <GatsbyLink to={getLocalizedPath(to, intl.locale)} {...props}>
      {children}
    </GatsbyLink>
  );
};

Link.propTypes = {
  children: PropTypes.node,
  to: PropTypes.string,
  isExternal: PropTypes.bool,
  hash: PropTypes.string,
};

Link.defaultProps = {
  children: '',
  to: '',
  isExternal: false,
  hash: '',
};

export default Link;
