import React from 'react';
import PropTypes from 'prop-types';
import tw, { styled } from 'twin.macro';

import NavItems from '@components/header/nav-items';
import NavBtn from '@components/header/nav-btn';
import NavLang from '@components/header/nav-lang';

const Wrapper = styled.div`
  ${tw`
    relative fixed inset-0 pt-80 bg-white transition-all duration-400 overflow-y-auto z-30
    lg:static lg:flex lg:items-center lg:p-0 lg:overflow-visible
  `}

  &.hidden { ${tw`left-full -right-full`} }
`;

const Nav = ({ isMenuActive, changeMenuState, switchPath }) => (
  <Wrapper className={!isMenuActive && 'hidden'}>
    <NavItems changeMenuState={changeMenuState} />
    <NavBtn />
    <NavLang switchPath={switchPath} />
  </Wrapper>
);

Nav.defaultProps = {
  switchPath: [],
};

Nav.propTypes = {
  isMenuActive: PropTypes.bool.isRequired,
  changeMenuState: PropTypes.func.isRequired,
  switchPath: PropTypes.arrayOf(PropTypes.string),
};

export default Nav;
