import React, { useState, useEffect } from 'react';
import tw, { styled, css } from 'twin.macro';
import { useCookies } from 'react-cookie';
import { FormattedMessage } from 'gatsby-plugin-intl';

import Text from '@components/text';
import LinkDefault from '@components/link';
import ShapeDefault from '@images/cookies-shape.svg';
import CoffeeDefault from '@images/cookies-coffee.svg';

const Wrapper = styled.div`
  transition: opacity .3s, bottom 0s .3s;
  ${tw`fixed bottom-0 left-1/2 items-center pt-14 pb-18 px-70 transform -translate-x-1/2 z-10 md:py-24`}
  ${({ show }) => (show ? tw`flex` : tw`hidden`)}
  ${({ exitAnimation }) => (exitAnimation && css`
    bottom: -200px !important;
    ${tw`opacity-0`}
  `)}
  
  @media (min-width: 768px) { bottom: 10px; }
`;

const Shape = styled(ShapeDefault)`
  left: -160px;
  right: -160px;
  ${tw`absolute top-0 h-full -z-1 sm:left-0 sm:w-full`}
  
  @media (min-width: 640px) { right: initial; }
`;

const Coffee = tw(CoffeeDefault)`hidden md:block`;

const Content = tw.div`w-250 mr-20 opacity-80 md:w-470 md:mx-30 lg:w-610`;

const Link = tw(LinkDefault)`underline`;

const Exit = tw.div`relative w-30 h-30 cursor-pointer`;

const Bar = styled.span`
  ${tw`absolute top-1/2 left-1/2 inline-block w-23 h-2 bg-white rounded-full transform -translate-x-1/2 -translate-y-1/2`}
  ${({ left }) => (left ? tw`rotate-45` : tw`-rotate-45`)}
`;

const Cookies = () => {
  const [cookies, setCookie] = useCookies(['name']);
  const [exitAnimation, handleExit] = useState(false);
  const [show, handleVisibility] = useState(false);

  const handleCookies = () => {
    handleExit(true);
    setCookie('cookiesAccepted', true);
  };

  useEffect(() => {
    handleVisibility(!cookies.cookiesAccepted);
  }, [cookies.cookiesAccepted]);

  return (
    <Wrapper
      show={show}
      exitAnimation={exitAnimation}
    >
      <Shape />
      <Coffee />
      <Content>
        <Text color="pink" fontSizes={[14, 16]} fontWeight={600} lineHeight={1.4} letterSpacing={20}>
          <FormattedMessage id="cookies.title" />
        </Text>
        <Text color="white" fontSizes={[14, 16]} fontWeight={300} lineHeight={1.4} letterSpacing={20}>
          <FormattedMessage id="cookies.desc" />
          <Link to="/privacy-policy">
            <FormattedMessage id="cookies.link" />
          </Link>
          .
        </Text>
      </Content>
      <Exit onClick={handleCookies}>
        <Bar />
        <Bar left />
      </Exit>
    </Wrapper>
  );
};

export default Cookies;
